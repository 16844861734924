import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const GamesPage = () => (
  <Layout>
    <SEO title="Online Közösségi játékok a Pandémia idejére" />
    <h1>Online Közösségi játékok a Pandémia idejére</h1>
    
    <div className="content-wrapper">
        <h3 className="health-icon"><i className="fas fa-plus"></i> Közösségi</h3>
        <div className="header-wrapper">
            <div className="cardify-content-wrapper support">
                <a className="cardify-content-card" href="https://garticphone.com/?reference=annyiavilag.hu" target="_blank">
                    <h5 className="main-content-card-title">GarticPhone</h5>
                    <p className="cardify-content-alt">#fun #draw</p>
                </a>
                <a className="cardify-content-card" href="https://skribbl.io/?reference=annyiavilag.hu" target="_blank">
                    <h5 className="main-content-card-title">Skribbl.io</h5>
                    <p className="cardify-content-alt">#fun #draw</p>
                </a>
            </div>
        </div>
    </div>
  </Layout>
)

export default GamesPage
